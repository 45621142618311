import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translation JSON files
import enTranslation from "./en.json";
import laTranslation from "./la.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  la: {
    translation: laTranslation,
  }
};

// Get user language preference from localStorage
const userLang = localStorage.getItem("userLang");

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: userLang || "la", // Set default language to "la" if userLang is not found

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;