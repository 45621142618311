import { Container, Row, Col } from "react-bootstrap";
import navIcon2 from "../assets/img/nav-icon2.svg";
import tikTok from "../assets/img/tik-tok.svg";
import logoheader from '../assets/img/unimarket.png';
import { useState} from "react";
import { useTranslation } from 'react-i18next';

export const Footer = () => {

  const [lang, setLang] = useState("la");
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang)
    localStorage.setItem("userLang", lang);
  };
  
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <p></p>
          <Col size={12} sm={5}>
          <h6> {t("address")}</h6>
            <p> {t("provinceAddress")}</p>
            <p> +856 20 9679 1992</p>
            <p> unitechlao.info@gmail.com</p>
          </Col>
          <Col size={12} sm={4}>
          <h6> {t("services")}</h6>
            <p> {t("services1")}</p>
            <p> {t("services2")}</p>
            <p> {t("services3")}</p>
          </Col>

          <Col size={12} sm={3} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.tiktok.com/@unitechlao"><img src={tikTok} alt="Icon" /></a>
              <a href="https://www.facebook.com/unitechlaos.official"><img src={navIcon2} alt="Icon" /></a>
              {/* <a href="#"><img src={navIcon3} alt="Icon" /></a> */}
            </div>
            <img src={logoheader} alt="Logo" />
            <p> &copy; Copyright 2023-UNITECH. All Rights Reserved</p>
            <p-version> Version 2.0 (19082023)</p-version>

          </Col>
        </Row>
      </Container>
    </footer>
  )
}
