import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import productImage from "../assets/img/unitech-cover.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next';


export const Product = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [setIndex] = useState(1);
  const toRotate = ["Web Developer", "Product", "Web Design"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  const [lang, setLang] = useState("la");
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang)
    localStorage.setItem("userLang", lang);
  };

  return (
    
    <section className="product" id="product">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">ຜະ​ລ​ິດ​ຕະ​ພັນ​ຂອງ​ພວກ​ເຮົາ</span>

                  <p> Unimarket ແມ່ນ Platform ສຳຫຼັບໃຫ້ພໍ່ຄ້າແມ່ຄ້າຊາວຂາຍທີ່ຕ້ອງການເປັນເຈົ້າຂອງ ເວບໄຊ Ecommerceໃນລາຄາແສນຖືກ ແລະ ມີຄຸນນະພາບ ພ້ອມມີຄົນຊ່ວຍໃຫ້ຄຳປຶກສາຄົບຖ້ວນ.</p>
                  <p>1. ຜູ້ໃຊ້ບໍລິການ ແມ່ນຈະໄດ້ມີເວບໜ້າຮ້ານເປັນຂອງໂຕເອງ ແລະ ສາມາດຈັດການຂໍ້ມູນທຸກຢ່າງໄດ້ດ້ວຍຕົວເອງ</p>
                  <p>2. ຄຸ້ມຄ່າ ໃຊ້ງົບປະມານຖືກ ແລະ ປະຢັດ ໃນລາຄາເລີ່ມຕົ້ນ 3 ໂດລາ ຕໍ່ເດືອນ</p>
                  <p>3. ສາມາດເພີ່ມ function ໄດ້ໃນລາຄາເລີ່ມຕົ້ນ 1 ໂດລາ ແລະ ອີງຕາມຄວາມຕ້ອງການ.</p>
                  <p>4. ມີທີມຊ່ວຍ support ຕະຫລອດ 24 ຊົ່ວໂມງ ເພື່ອແກ້ໄຂບັນຫາເວລາໃຊ້ງານຕົວຈິງ.</p>
                  <p>5. Unimarket ຍັງເປັນທີ່ປຶກສາ ດ້ານການຕະຫລາດ ດ້ານການໂຄສະນາ ເພື່ອໃຫ້ຊ່ວຍໃຫ້ ຜູ້ໃຊ້ບໍລິການ ເພີ່ມຍອດຂາຍໄດ້ດີຂື້ນ.</p>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={productImage} alt="Product Img" />
                </div>}
            </TrackVisibility>
          </Col>
          
          <section id="pricing" className="pricing sections-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
              <span className="tagline">ລາ​ຄາ​ຕໍ່​ແພັກ​ເກັດ</span>
              
                <p>ໃນ​ລາ​ຄາ​ທີ່​ທ່ານ​ສາ​ມາດ​ຈັ​ບ​ຕ້ອງ​ໄດ້. ພິເສດ ສຳລັບລູກຄ້າໃໝ່ ເປີດບັນຊີ 1 ເດືອນ ແຖມອີກ 1 ເດືອນຄຸ້ມສຸດໆ</p>
              </div>

              <div className="row g-4 py-lg-4" data-aos="zoom-out" data-aos-delay="100">
                <div className="col-lg-3">
                  <div className="pricing-item">
                    <h3>ແພັກ​ເກັດ UNI01</h3>
                    <h4><sup>$</sup>3<span> / 1 ເດືອນ</span></h4>
                    <ul>
                      <li><i className="bi bi-check"></i> ເປີດ​ນຳ​ໃຊ້​ລະ​ບົບ​ພາຍ​ໃນ 25-30 ນາ​ທີ</li>
                      <li><i className="bi bi-check"></i>​ຊັບ​ພ໋ອດຕາມ​ໂມງ​ລາດ​ຊະ​ການ</li>
                      <li><i className="bi bi-check"></i>​ມີ​ເອ​ກະ​ສານຄູ່​ມື​ໃຫ້</li>
                      <li><i className="bi bi-check"></i>ສາ​ມາດ​ໃຫ້​ຄຳ​ປຶກ​ສາ​ໄດ້​ຕາມ​ໂມງ​ລາດ​ຊະ​ການ</li>
                    </ul>
                    <div className="text-center"><a href="https://wa.me/+8562056891992/?text=ສະບາຍດີສົນ​ໃຈ​ລົງ​ທະ​ບຽນ​ນຳ​ໃຊ້​ລະ​ບົບ, ແພັກ​ເກັດ UNI01 ($3/1ເດືອນ)" className="buy-btn">ລົງ​ທະ​ບຽນ</a></div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="pricing-item featured">
                    <h3>ແພັກ​ເກັດ UNI02</h3>
                    <h4><sup>$</sup>7<span> / 3 ເດືອນ</span></h4>
                    <ul>
                      <li><i className="bi bi-check"></i> ເປີດ​ນຳ​ໃຊ້​ລະ​ບົບ​ພາຍ​ໃນ 15-20 ນາ​ທີ</li>
                      <li><i className="bi bi-check"></i>​ຊັບ​ພ໋ອດຕາມ​ໂມງ​ລາດ​ຊະ​ການ</li>
                      <li><i className="bi bi-check"></i>​ມີ​ເອ​ກະ​ສານຄູ່​ມື​ໃຫ້</li>
                      <li><i className="bi bi-check"></i>ສາ​ມາດ​ໃຫ້​ຄຳ​ປຶກ​ສາ​ໄດ້​ຕາມ​ໂມງ​ລາດ​ຊະ​ການ</li>
                    </ul>
                    <div className="text-center"><a href="https://wa.me/+8562056891992/?text=ສະບາຍດີສົນ​ໃຈ​ລົງ​ທະ​ບຽນ​ນຳ​ໃຊ້​ລະ​ບົບ, ແພັກ​ເກັດ UNI02 ($7/3ເດືອນ)" className="buy-btn">ລົງ​ທະ​ບຽນ</a></div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="pricing-item">
                    <h3>ແພັກ​ເກັດ UNI03</h3>
                    <h4><sup>$</sup>15<span> / 6 ເດືອນ</span></h4>
                    <ul>
                      <li><i className="bi bi-check"></i> ເປີດ​ນຳ​ໃຊ້​ລະ​ບົບ​ພາຍ​ໃນ 10-15 ນາ​ທີ</li>
                      <li><i className="bi bi-check"></i>​ຊັບ​ພ໋ອດຕາມ​ໂມງ​ລາດ​ຊະ​ການ</li>
                      <li><i className="bi bi-check"></i>​ມີ​ເອ​ກະ​ສານຄູ່​ມື​ໃຫ້</li>
                      <li><i className="bi bi-check"></i>ສາ​ມາດ​ໃຫ້​ຄຳ​ປຶກ​ສາ​ໄດ້​ຕາມ​ໂມງ​ລາດ​ຊະ​ການ</li>
                    </ul>
                    <div className="text-center"><a href="https://wa.me/+8562056891992/?text=ສະບາຍດີສົນ​ໃຈ​ລົງ​ທະ​ບຽນ​ນຳ​ໃຊ້​ລະ​ບົບ, ແພັກ​ເກັດ UNI03 ($15/6ເດືອນ)" className="buy-btn">ລົງ​ທະ​ບຽນ</a></div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="pricing-item">
                    <h3>ແພັກ​ເກັດ UNI04</h3>
                    {/* <div className="icon">
                      <i className="bi bi-send"></i>
                    </div> */}
                    <h4><sup>$</sup>28<span> / 12 ເດືອນ</span></h4>
                    <ul>
                      <li><i className="bi bi-check"></i> ເປີດ​ນຳ​ໃຊ້​ລະ​ບົບ​ພາຍ​ໃນ 3-5 ນາ​ທີ</li>
                      <li><i className="bi bi-check"></i>​ຊັບ​ພ໋ອດຕາມ​ໂມງ​ລາດ​ຊະ​ການ</li>
                      <li><i className="bi bi-check"></i>​ມີ​ເອ​ກະ​ສານຄູ່​ມື​ໃຫ້</li>
                      <li><i className="bi bi-check"></i>ສາ​ມາດ​ໃຫ້​ຄຳ​ປຶກ​ສາ​ໄດ້​ຕາມ​ໂມງ​ລາດ​ຊະ​ການ</li>
                    </ul>
                    <div className="text-center"><a href="https://wa.me/+8562056891992/?text=ສະບາຍດີສົນ​ໃຈ​ລົງ​ທະ​ບຽນ​ນຳ​ໃຊ້​ລະ​ບົບ, ແພັກ​ເກັດ UNI04 ($28/12ເດືອນ)" className="buy-btn">ລົງ​ທະ​ບຽນ</a></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Row>
      </Container>
    </section>
  )
}
